import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";



@Injectable()
export class SendMailService {

   


    

  constructor(private http: HttpClient) {
 
  }

      Send(formData:FormData):Observable<any> {
        
      return  this.http.post<any>('https://devsite.dentzavenue.com/angularPhp.php',formData);
  }


}