import { Component, HostListener, OnInit } from '@angular/core';
import { SendMailService } from 'src/app/services/sendMailService';

import {ContactDetails} from '../../models/contactDetails';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private sendMail:SendMailService) { }

  public innerWidth: any;
  public IsLargescreenSize:boolean = true;
   details:ContactDetails = new ContactDetails();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.innerWidth = window.innerWidth;
      
      if(this.innerWidth > 600){
          this.IsLargescreenSize = true;
      }else{
          this.IsLargescreenSize = false;
      }
  }

  RepondList = [
    { id: 1, name: 'Email' },
    { id: 2, name: 'Phone' },
  ]

  PrefferedTimeList =[
    { id: 1, name: '8:00 am' },
    { id: 2, name: '8:30 am' },
    { id: 3, name: '9:00 am' },
    { id: 4, name: '9:30 am' },
    { id: 5, name: '10:00 am' },
    { id: 6, name: '10:30 am' },
    { id: 7, name: '11:00 am' },
    { id: 8, name: '11:30 am' },
    { id: 9, name: '12:00 pm' },
    { id: 10, name: '12:30 pm' },
    { id: 11, name: '1:00 pm' },
    { id: 12, name: '1:30 pm' },
    { id: 13, name: '2:00 pm' },
    { id: 14, name: '2:30 pm' },
    { id: 15, name: '3:00 pm' },
    { id: 16, name: '3:30 pm' },
    { id: 17, name: '4:00 pm' },
    { id: 18, name: '4:30 pm' },
    { id: 19, name: '5:00 pm' },
    { id: 20, name: '5:30 pm' },
    { id: 21, name: '6:00 pm' },
    { id: 22, name: '6:30 pm' },
    { id: 23, name: '7:00 pm' },
    { id: 24, name: '7:30 pm' },
    { id: 25, name: '8:00 pm' },
  ]
  ngOnInit() {
  }


  sendEmail(){
    var name = 'test';
    const formData : FormData = new FormData();
    formData.append('name',name);
    this.sendMail.Send(formData).subscribe(response =>{
    console.log(response);
    },
    err=>{
      console.log(err);
    }
    )
  }

}
