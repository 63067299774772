import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  IsSelectedDoctors:boolean=true;
  IsSelectedInside:boolean= false;
  IsSelectedOutside:boolean = false;
  constructor() { }
  public innerWidth: any;
  public IsLargescreenSize:boolean = true;
  ngOnInit() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.innerWidth = window.innerWidth;
      
      if(this.innerWidth > 600){
          this.IsLargescreenSize = true;
      }else{
          this.IsLargescreenSize = false;
      }
  }

  SelectedDoctors(){
    this.IsSelectedDoctors = true;
    this.IsSelectedInside = false;
    this.IsSelectedOutside = false;
  }

  SelectedInside(){
    this.IsSelectedDoctors = false;
    this.IsSelectedInside = true;
    this.IsSelectedOutside = false;
  }

  SelectedOutside(){
    this.IsSelectedOutside = true;
    this.IsSelectedInside = false;
    this.IsSelectedDoctors = false;
  }

}
