import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-patients',
  templateUrl: './new-patients.component.html',
  styleUrls: ['./new-patients.component.scss']
})
export class NewPatientsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  public innerWidth: any;
  public IsLargescreenSize:boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.innerWidth = window.innerWidth;
      
      if(this.innerWidth > 600){
          this.IsLargescreenSize = true;
      }else{
          this.IsLargescreenSize = false;
      }
  }
}
