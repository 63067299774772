import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-services',
  templateUrl: './sub-services.component.html',
  styleUrls: ['./sub-services.component.scss']
})
export class SubServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  public innerWidth: any;
  public IsLargescreenSize:boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.innerWidth = window.innerWidth;
      
      if(this.innerWidth > 600){
          this.IsLargescreenSize = true;
      }else{
          this.IsLargescreenSize = false;
      }
  }

}
