import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExtractionsComponent } from './extractions/extractions.component';
import { FillingsComponent } from './fillings/fillings.component';
import { SubServicesComponent } from './sub-services.component';



const  routes:  Routes  = [
    {
    path:  '',
    component:  SubServicesComponent,
    children: [
    {
    path:  'extractions',
    component:  ExtractionsComponent
    },
  {
    path:  'fillings',
    component:  FillingsComponent
    },

    
 
    ]
    }
    ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubServicesRouterModule { }
