import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { WhyChooseUsComponent } from './pages/why-choose-us/why-choose-us.component';
import { NewPatientsComponent } from './pages/new-patients/new-patients.component';
import { DentalEmergencyComponent } from './pages/dental-emergency/dental-emergency.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SmileGalleryComponent } from './pages/smile-gallery/smile-gallery.component';
import { ServicesComponent } from './pages/services/services.component';
import { SubServiceModule } from './pages/sub-services/sub-services.module';
import { HttpClientModule } from '@angular/common/http';
import { SendMailService } from './services/sendMailService';


@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    WhyChooseUsComponent,
    NewPatientsComponent,
    DentalEmergencyComponent,
    ContactUsComponent,
    SmileGalleryComponent,
    ServicesComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    SubServiceModule,
    HttpClientModule
  ],
  providers: [SendMailService],
  bootstrap: [AppComponent],

})
export class AppModule { }
