import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ExtractionsComponent } from "./extractions/extractions.component";
import { SubServicesRouterModule } from "./sub-service.router.module";
import { SubServicesComponent } from "./sub-services.component";
import { FillingsComponent } from './fillings/fillings.component';

@NgModule({
    declarations: [
        SubServicesComponent,
        ExtractionsComponent,
        FillingsComponent
    ],
    imports: [
        SubServicesRouterModule,
    FormsModule,
    CommonModule
    ],
    providers: [],

  
  })
  export class SubServiceModule { }