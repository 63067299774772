import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'denz-avenue-front';
  shownavTop:boolean = true;
  constructor(  private element : ElementRef,private renderer : Renderer2) {}


  

  ngOnInit() {
    var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
   console.log('navbar',navbar);
    
    this.renderer.listen('window', 'scroll', (event) => {
        const number = window.scrollY;
        if (number > 150 || window.pageYOffset > 150) {
            // add logic
            
            navbar.classList.add('fixed-top','navbackground');
            this.shownavTop = false;
        } else {
         
          navbar.classList.remove('fixed-top','navbackground');
            this.shownavTop = true;
            // remove logic
          
        }
    });


}
}
