import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { DentalEmergencyComponent } from './pages/dental-emergency/dental-emergency.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { NewPatientsComponent } from './pages/new-patients/new-patients.component';
import { ServicesComponent } from './pages/services/services.component';
import { SmileGalleryComponent } from './pages/smile-gallery/smile-gallery.component';
import { WhyChooseUsComponent } from './pages/why-choose-us/why-choose-us.component';


const routes: Routes = [
  { path: '', redirectTo: 'main-page', pathMatch: 'full' },
  { path: 'main-page', component: MainPageComponent },
  { path: 'why-choose', component: WhyChooseUsComponent },
  { path: 'new-patients', component: NewPatientsComponent },
  { path: 'dental-emergency', component: DentalEmergencyComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'smile-gallery', component: SmileGalleryComponent },
  { path: 'services', component: ServicesComponent },
  {
    path: 'sub-services',
    loadChildren: () => import('../app/pages/sub-services/sub-services.module').then(m => m.SubServiceModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
