import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-choose-us',
  templateUrl: './why-choose-us.component.html',
  styleUrls: ['./why-choose-us.component.scss']
})
export class WhyChooseUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  public innerWidth: any;
  public IsLargescreenSize:boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.innerWidth = window.innerWidth;
      
      if(this.innerWidth > 600){
          this.IsLargescreenSize = true;
      }else{
          this.IsLargescreenSize = false;
      }
  }

}
