import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-smile-gallery',
  templateUrl: './smile-gallery.component.html',
  styleUrls: ['./smile-gallery.component.scss']
})
export class SmileGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  public innerWidth: any;
  public IsLargescreenSize:boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.innerWidth = window.innerWidth;
      
      if(this.innerWidth > 600){
          this.IsLargescreenSize = true;
      }else{
          this.IsLargescreenSize = false;
      }
  }
}
